import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import ShowChartOutlinedIcon from '@mui/icons-material/ShowChartOutlined';
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined';
import { useTheme, Box, Typography, Link, Button } from '@mui/material';
import { GridSortDirection } from '@mui/x-data-grid-pro';
import { AlertMessage, MainHeader } from '@eposnow/ui-core';
import { InfoOutlined } from '@mui/icons-material';
import { GetTransactionsDTO, useTransactionsPaged } from '../../api/transactions';
import { SettingsContext } from '../../context/SettingsContext';
import { TransactionFiltersContext } from '../../context/TransactionFiltersContext';
import { defer } from '../../helpers/helpers';
import PageCache, { CacheType } from '../../helpers/pageCache';
import { TransactionSummary } from '../../models/transactions';
import ReportTable from './components/ReportTable';
import { UserContext } from '../../context/UserContext';
import { UIContext } from '../../context/UIContext';
import ReportListMobile from './components/ReportListMobile';
import { ROWS_PER_PAGE_MOBILE } from './constants';
import { MILLIS_PER_DAY } from '../../helpers/dateUtils';
import WarningBanner from '../../components/WarningBanner';

const cache = new PageCache<TransactionSummary>();
const cacheAdmin = new PageCache<TransactionSummary>(CacheType.NO_CACHE);
const SHOW_WEEKEND_NOTICE_DATE_KEY_TRANSACTIONS = 'alerts.weekendNoticeTransactions';

const Transactions = (props: { cacheType?: CacheType; adminView?: boolean }) => {
// eslint-disable-next-line react/destructuring-assignment
  const navigate = useNavigate();
  const theme = useTheme();
  const { isMobile, isTablet } = useContext(UIContext);
  const { filters } = useContext(TransactionFiltersContext);
  const { settings } = useContext(SettingsContext);
  const { locale } = useContext(UserContext);
  const { view } = settings;
  const { cacheType, adminView } = props;
  const [descriptionHeader, setDescriptionHeader] = useState(false)
  const isWeekend = ([0,6].includes(new Date().getDay()));
  const [showWeekendNotice, setShowWeekendNotice] = useState(false);

  useEffect(() => {
    if (cacheType) {
      cache.setCacheType(cacheType);
      cacheAdmin.setCacheType(cacheType);
    }
  }, [cacheType]);

  useEffect(() => {
    const showWeekendNoticeDate = +(localStorage.getItem(SHOW_WEEKEND_NOTICE_DATE_KEY_TRANSACTIONS) || '');
    setShowWeekendNotice(showWeekendNoticeDate <= Date.now());
  }, []);

  const { t } = useTranslation();
  const defaultRowLimit = (isMobile || isTablet) ? ROWS_PER_PAGE_MOBILE : parseInt(settings.rowsPerPage, 10);
  const location = useLocation();

  const [search, setSearch] = useState('');
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(defaultRowLimit);
  const [order, setOrder] = useState('asc' as GridSortDirection);
  const [orderBy, setOrderBy] = useState('date');
  // so basically when we change the view a new fetch to the API must be done.
  // if the "current" view differs from the view we last fetched, that means that we just done the switch
  // and we are rerendering, but we are still awaiting to fetch the data as it is a deferred call
  // we should treat this as a loading status.
  const [lastFetchedDataView, setLastFetchedDataView] = useState(view);
  const [totalDataFetched, setTotalDataFetched] = useState<TransactionSummary[]>([]);

  const linkNavigation = useCallback(
    (path: string | undefined) => {
      if (path) {
        navigate(path);
      }
    },
    [navigate]
  );

  const {
    data: apiData,
    refetch: fetchTransactions,
    isFetching,
    isError,
  } = useTransactionsPaged<TransactionSummary>(
    {
      startDate: filters.periodFrom,
      endDate: filters.periodTo,
      location: filters.location ? filters.location.id : undefined,
      device: filters.device ? filters.device.id : undefined,
      locationArea: filters.locationArea ? filters.locationArea.id : undefined,
      staff: filters.staff ? filters.staff.id : undefined,
      paymentMethod: filters.paymentMethod ? filters.paymentMethod.id : undefined,
      search: search || undefined,
      page,
      limit,
      sortBy: orderBy,
      sortOrder: order,
    } as GetTransactionsDTO,
    cache,
    'transactions',
    {
      onSuccess: (result) => {
        if (result?.data) setTotalDataFetched([...totalDataFetched, ...result.data]);
        setLastFetchedDataView(view);
      },
      enabled: !adminView,
    },
    false
  );

  const {
    data: apiDataAdmin,
    refetch: fetchTransactionsAdmin,
    isFetching: isFetchingAdmin,
    isError: isErrorAdmin,
  } = useTransactionsPaged<TransactionSummary>(
    {
      startDate: filters.periodFrom,
      endDate: filters.periodTo,
      search: search || undefined,
      page,
      limit,
      location: filters.location ? filters.location.id : undefined,
      device: filters.device ? filters.device.id : undefined,
      sortBy: orderBy,
      sortOrder: order,
      companyGuid: filters.companyGuid,
      hasActiveFlag: filters.hasActiveFlag,
      activeFlagCategory: filters.activeFlagCategory,
    } as GetTransactionsDTO,
    cacheAdmin,
    'transactionsAdmin',
    {
      onSuccess: (result) => {
        if (result?.data) setTotalDataFetched([...totalDataFetched, ...result.data]);
        setLastFetchedDataView(view);
      },
      enabled: !!adminView,
    },
    true
  );

  const refetch = useCallback(async () => {
    if (!adminView) {
      await fetchTransactions();
    }
    if (adminView){
      await fetchTransactionsAdmin();
    }
  }, [adminView, fetchTransactions, fetchTransactionsAdmin]);

  // support is only 'es' or 'en_US' currently and 'en' forwards on
  let supportLink = `https://support.eposnow.com/s/article/Transaction-Report?language=${locale.substring(0, 2)}`;
  if (locale.substring(0, 2) === 'es') {
    supportLink = 'https://support.eposnow.com/s/article/Informe-Transacciones?language=es'
  }

  // Force reset the page and fetch data on filters or admin view change
  useEffect(
    () =>
      defer(() => {
        setTotalDataFetched([]);
        setPage(0);
        refetch().then();
      }),
    [filters, search, refetch, location]
  )

  return (
    <>
    {!isMobile && !isTablet &&
      <Box
        sx={{
          marginLeft: 0,
          marginBottom: { xs: 1, sm: 2 },
          paddingX: { xs: 2, sm: 0 },
        }}
      >
        {isWeekend && showWeekendNotice &&
          <WarningBanner
            title={t('screens.transactions.weekendNotice')}
            alertDescription={t('screens.transactions.weekendNoticeText')}
            buttonText={t('actions.gotIt')}
            action={() => {
              setShowWeekendNotice(false);
              localStorage.setItem(SHOW_WEEKEND_NOTICE_DATE_KEY_TRANSACTIONS, String(Date.now() + (MILLIS_PER_DAY * 2)));
            }}
          />
        }
        <MainHeader
          title={adminView ? t('nav.transactionsAdmin') : t('screens.transactions.title')}
          nav={[
            {
              text: t('nav.reporting'),
              link: '/',
              icon: <ShowChartOutlinedIcon sx={{ mr: 1 }} fontSize="inherit" />,
              dataQaId: 'headerNavTransactionReports',
            },
            {
              text: adminView ? t('nav.transactionsAdmin') : t('nav.transactions'),
              link: '/transactions',
              icon: adminView ? (
                <AdminPanelSettingsOutlinedIcon sx={{ mr: 1 }} fontSize="inherit" />
              ) : (
                <SummarizeOutlinedIcon sx={{ mr: 1 }} fontSize="inherit" />
              ),
              dataQaId: 'headerNavCompletedTransactionReports'
            },
          ]}
          theme={theme}
          linkNavigationFunction={linkNavigation}
        >
          <Typography variant="body1">
            {t('screens.transactions.description')}
            <Link
              href={supportLink}
              sx={{ textDecoration: 'underline', marginLeft: '0.3rem', fontWeight: '600' }}
            >
              {t('screens.transactions.learnMore')}
            </Link>
          </Typography>
          <Typography variant="body1">
            {t('screens.transactions.classicLinkPreText')}
            <Link
              href={`${process.env.REACT_APP_LINK_BACK_OFFICE}Pages/Reporting/CompleteTransactions.aspx`}
              sx={{ textDecoration: 'underline', marginLeft: '0.3rem', fontWeight: '600' }}
            >
            {t('screens.transactions.classicLinkText')}
            </Link>
          </Typography>
        </MainHeader>
      </Box>
    }
        {(isMobile || isTablet) &&
          <Box
          sx={{
            marginLeft: 0,
            marginBottom: { xs: 1, sm: 2 },
            paddingX: { xs: 2, sm: 0 },
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          {isWeekend && showWeekendNotice && 
             <WarningBanner
             title={t('screens.transactions.weekendNotice')}
             alertDescription={t('screens.transactions.weekendNoticeText')}
             buttonText={t('actions.gotIt')}
             action={() => {
               setShowWeekendNotice(false);
               localStorage.setItem(SHOW_WEEKEND_NOTICE_DATE_KEY_TRANSACTIONS, String(Date.now() + (MILLIS_PER_DAY * 2)));
             }}
           />
          }
          <Box sx={{display: 'flex', flexDirection: 'row'}}>
            <Typography
            variant='h2'
            sx={{
            }}>
              {adminView ? t('nav.transactionsAdmin') : t('screens.transactions.title')}
              </Typography>
              <InfoOutlined
              onClick={() => setDescriptionHeader(!descriptionHeader)}
              sx={{
                marginLeft: '4px',
              }}
              />
              </Box>
              {descriptionHeader &&
              <Box>
            <Typography variant="body1">
              {t('screens.transactions.description')}
              <Link
                href={supportLink}
                sx={{ textDecoration: 'underline', marginLeft: '0.3rem', fontWeight: '600' }}
              >
                {t('screens.transactions.learnMore')}
              </Link>
            </Typography>
            <Typography variant="body1">
              {t('screens.transactions.classicLinkPreText')}
              <Link
                href={`${process.env.REACT_APP_LINK_BACK_OFFICE}Pages/Reporting/CompleteTransactions.aspx`}
                sx={{ textDecoration: 'underline', marginLeft: '0.3rem', fontWeight: '600' }}
              >
              {t('screens.transactions.classicLinkText')}
              </Link>
            </Typography>
            </Box>
            }
        </Box>
        }
      {!adminView && !(isMobile || isTablet) && (
        <ReportTable
          key="userTable"
          rows={apiData ? apiData.data || [] : []}
          refetch={refetch}
          refresh={() => {
            cache.clear();
            refetch().then();
          }}
          isError={isError}
          search={search}
          setSearch={setSearch}
          page={page}
          setPage={setPage}
          rowsPerPage={limit}
          setRowsPerPage={setLimit}
          isLoading={isFetching}
          order={order as GridSortDirection}
          setOrder={setOrder}
          orderBy={orderBy}
          setOrderBy={setOrderBy}
          count={apiData ? apiData.pagination.totalRecords : 0}
        />)
      }
      {adminView && !(isMobile || isTablet) && (
        <ReportTable
          key="adminTable"
          rows={apiDataAdmin ? apiDataAdmin.data || [] : []}
          refetch={refetch}
          refresh={() => {
            cache.clear();
            refetch().then();
          }}
          isError={isErrorAdmin}
          search={search}
          setSearch={setSearch}
          page={page}
          setPage={setPage}
          rowsPerPage={limit}
          setRowsPerPage={setLimit}
          isLoading={isFetchingAdmin || view !== lastFetchedDataView}
          order={order as GridSortDirection}
          setOrder={setOrder}
          orderBy={orderBy}
          setOrderBy={setOrderBy}
          count={apiDataAdmin ? apiDataAdmin.pagination.totalRecords : 0}
          adminView
        />)
      }
      {(isMobile || isTablet) &&  (
        <ReportListMobile
          key="mobileList"
          refetch={refetch}
          refresh={() => {
            cache.clear();
            refetch().then();
          }}
          page={page}
          setPage={setPage}
          setRowsPerPage={setLimit}
          order={order}
          setOrder={setOrder}
          orderBy={orderBy}
          adminView={adminView}
          setOrderBy={setOrderBy}
          count={apiData ? apiData.pagination.totalRecords : 0}
          isLoading={isFetching || view !== lastFetchedDataView}
          isError={isError}
          totalRows={totalDataFetched}
          emptyTotalRows={() => setTotalDataFetched([])}
          search={search}
          setSearch={setSearch}
        />
      )}
    </>
  );
};

export default Transactions;
