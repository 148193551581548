import {useContext, useEffect, useState} from "react";
import {
    GetTransactionsRequest,
    REPORT_TYPE_DETAILS,
    TransactionsReportType,
    useTransactions
} from "../api/transactions";
import {DiscountsSummary, TransactionSummary} from "../models/transactions";
import objectArrayToCSV from "./objectArrayToCSV";
import {PagedResult} from "./pageCache";
import { hasModule } from "./helpers";
import { UserContext } from "../context/UserContext";

export interface BulkExportParams extends GetTransactionsRequest {
    filename?: string;
    reportType: TransactionsReportType;
    isAdmin?: boolean;
}

const BULK_EXPORT_RATE = 500;
const useBulkExport = (params: BulkExportParams) => {
    const {filename, reportType, isAdmin} = params;
    const [fileUri, setFileUri] = useState<string | undefined>(undefined);
    const [error, setError] = useState<Error | undefined>(undefined);
    const [page, setPage] = useState(1);
    const [records, setRecords] = useState<TransactionSummary[]>([]);
    const [progress, setProgress] = useState<number | undefined>(undefined);
    const { userModules } = useContext(UserContext);
    const hasModuleInvoiceNumber = hasModule(userModules, "ModuleInvoiceNumbers");
    const hasPspReferencesNeededModules = hasModule(userModules, '_EposNowBalancePlatform') ||
    hasModule(userModules, '_EposNowPayments');

    function resetState() {
        setPage(1);
        setRecords([]);
        setProgress(undefined);
        setFileUri(undefined);
        setError(undefined);
    }

    // It is important to run first this effect to reset the state. Otherwise we will have race conditions
    // when running the export multiple times, where the last page request is fired and then it may mess with our results
    useEffect(() => {
        if (!filename) {
            return;
        }
        resetState();
    }, [filename]);

    if (reportType === REPORT_TYPE_DETAILS) {
        setError(new Error("Details export not implemented"));
    }

    const transformer = (key: string, val: unknown) => {
        if (key === "discountsSummary") return (val as DiscountsSummary).totalDiscountAmount;
        if (key === 'invoiceNumbers') return (val as string[])?.join(',')
        if (key === 'pspReferences' && hasPspReferencesNeededModules) return (val as string[])?.join(',')
        return val;
    }

    const genBlob = <T>(rows: T[]): string => {
        const excludeColumns: (keyof T)[] = ["paymentLogo" as keyof T];
        if (!hasModuleInvoiceNumber) {
            excludeColumns.push("invoiceNumbers" as keyof T)
        }
        if(!hasPspReferencesNeededModules) {
            excludeColumns.push("pspReferences" as keyof T)
        }
        const blob = new Blob([objectArrayToCSV(rows, ",", "/", excludeColumns, transformer)], {type: "application/octet-stream"});
        return URL.createObjectURL(blob);
    }

    const onSuccess = <K>(data: PagedResult<K>, rows: K[], setRecordsMethod: (records: K[]) => void, fname: string) => {
        if (fname !== filename) return;
        const paginfo = data.pagination;
        setRecordsMethod(rows.concat(data.data));
        if (paginfo.totalPages && page >= paginfo.totalPages) {
            setProgress(100);
            setFileUri(genBlob(rows.concat(data.data)));
        } else if (filename) {
            setPage(page + 1);
            setProgress(Math.ceil(page / paginfo.totalPages * 100));
        }
    };

    const {
        isError,
    } = useTransactions<TransactionSummary>({
            ...params,
            page,
            limit: BULK_EXPORT_RATE
        }, `hospitality${filename}${page}`,
        {
            enabled: !!filename, onSuccess: (data) => {
                if (!filename) {
                    return;
                }
                onSuccess({
                    pagination: data.pagination,
                    data: data.transaction
                }, records, setRecords, filename || "");
            }
        }, isAdmin);

    useEffect(() => {
        if (isError) {
            setError(new Error("Fetch failed"));
        } else {
            setError(undefined);
        }
    }, [isError]);

    return {progress, fileUri, error};
}

export default useBulkExport;
