import {ListItemIcon, ListItemText, Menu, MenuItem, MenuList, MenuProps, Typography} from "@mui/material";
import FocusTrap from "@mui/material/Unstable_TrapFocus";
import React, {ReactElement} from "react";
import { ENTER_KEY, NUMPAD_ENTER_KEY, TAB_KEY } from "../constants";

// IMenuItem is non standard name for TS but I'm using it not to conflict with MUI own MenuItem element
export interface IMenuItem {
    title: string;
    onClick: () => void;
    icon?: ReactElement;
    iconPosition?: "left" | "right";

    rightText?: string;
    hidden?: boolean;
    dataQaId?: string;
}

export interface IconMenuProps {
    items: IMenuItem[];
    open: boolean;
    onClose?: () => void;
    ariaLabelledBy?: string;
    anchorEl?: HTMLElement;
    menuProps?: Partial<MenuProps>;
}

const IconMenu = (props: IconMenuProps) => {
    const {items, open, onClose, anchorEl, ariaLabelledBy, menuProps} = props;

    return <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={onClose}
        MenuListProps={{
            'aria-labelledby': ariaLabelledBy,
        }}
        {...menuProps}
        
    >
        <MenuList onKeyDown={() => {/* */}}>
            {items.filter((item) => !item.hidden).map((item, index) =>
            <FocusTrap open> 
                <MenuItem key={item.title} onClick={() => {
                    item.onClick();
                    if (onClose) onClose()
                }} 
                onKeyDown={(event: any) => {
                    if(event.code === ENTER_KEY || event.code === NUMPAD_ENTER_KEY){
                        item.onClick();    
                    }
                    if(event.code === TAB_KEY && onClose) {
                        onClose()
                    }
                }}
                    data-qa-id={item.dataQaId}>
                    {item.icon && item.iconPosition !== "right" && <ListItemIcon>
                        {item.icon}
                    </ListItemIcon>}
                    <ListItemText>{item.title}</ListItemText>
                    {item.icon && item.iconPosition === "right" &&
                        <ListItemIcon sx={{marginLeft: "22px", minWidth: "22px !important"}}>
                            {item.icon}
                        </ListItemIcon>}
                    {item.rightText &&
                        <Typography sx={{fontSize: "12px", color: "text.primary"}}>{item.rightText}</Typography>}
                </MenuItem>
            </FocusTrap>)}
        </MenuList>
    </Menu>
}

export default IconMenu;
