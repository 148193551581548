import {CSSProperties} from 'react';
import {FlagCategory} from './models/transactions';

const SUCCESS_MESSAGE_TIME = 3000;
const NAV_DRAWER_WIDTH = 296;
const NAV_CLOSED_DRAWER_WIDTH = 64;
const ADDRESS_DRAWER_WIDTH = 320;
const XS_WIDTH = 0;
const SM_WIDTH = 640;
const MD_WIDTH = 1024;
const LG_WIDTH = 1200;
const XL_WIDTH = 1536;
const TERMINALS_PER_PAGE = 2;
const ADDRESSES_PER_PAGE = 3;
const ADDRESSES_PER_LARGE_PAGE = 5;
const ANIMATION_MINIMUM_TIMEOUT = 1000;

const comboStyle: CSSProperties = {
  paddingBottom: '12px',
  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '24px',
  width: '100%',
};

const desktopButtonStyle: CSSProperties = {
  height: '48px',
  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  fontWeight: 600,
  zIndex: 99999,
  paddingLeft: '24px',
  paddingRight: '24px',
  marginLeft: '16px'
};
const mobileDialogStyle: CSSProperties = {
  display: 'flex', 
  minHeight: '103vh', 
  minWidth: '100vw', 
  overflow: 'hidden', 
  marginBottom: '2vh',
};
const mobileDialogTitleStyle: CSSProperties = {
  overflow: 'hidden',
  marginTop: '2rem',
  fontSize: '2rem',
  whiteSpace: 'normal',
};
const mobileDialogContentStyle: CSSProperties = {
  height: '80vw', 
  width: '100vw',
  fontSize: '2rem',
  overflow: 'hidden',
  alignItems: 'center',
  marginBottom: '2vh',
};
const mobileDialogContentTextStyle: CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  width: '80vw',
  marginBottom: '2vh',
  overflow: 'hidden', 
  paddingLeft: '1rem',
}
const mobileRadioGroupStyle: CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  marginBottom: '2vh',
  paddingRight: '1rem',
};
const mobileTextFieldStyle: CSSProperties = {
  display: 'flex',
  justifyContent: 'center', 
  height: 'auto',
  width: '80vw',
  margin: '0',
  paddingLeft: '1rem',
}
const mobileButtonStyle: CSSProperties = {
  marginBottom: '8px'
};
const desktopSecondaryButtonStyle: CSSProperties = {
  height: '48px',
  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  fontWeight: 600,
  backgroundColor: 'background.default',
  zIndex: 99999,
  paddingLeft: '24px',
  paddingRight: '24px'
};
const mobileSecondaryButtonStyle: CSSProperties = {
  height: '40px',
  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  fontWeight: 600,
  backgroundColor: 'background.default',
  zIndex: 99999,
  paddingLeft: '24px',
  paddingRight: '24px'
};

export {
  SUCCESS_MESSAGE_TIME,
  NAV_DRAWER_WIDTH,
  NAV_CLOSED_DRAWER_WIDTH,
  ADDRESS_DRAWER_WIDTH,
  ADDRESSES_PER_PAGE,
  XS_WIDTH,
  SM_WIDTH,
  MD_WIDTH,
  LG_WIDTH,
  XL_WIDTH,
  TERMINALS_PER_PAGE,
  ADDRESSES_PER_LARGE_PAGE,
  ANIMATION_MINIMUM_TIMEOUT,
  comboStyle,
  desktopButtonStyle,
  mobileDialogStyle,
  mobileDialogTitleStyle,
  mobileDialogContentStyle,
  mobileDialogContentTextStyle,
  mobileRadioGroupStyle,
  mobileTextFieldStyle,
  mobileButtonStyle,
  desktopSecondaryButtonStyle,
  mobileSecondaryButtonStyle,
};

export const transactionFeedbackOptions: Record<string, FlagCategory> = {
  wrongData: {},
  missingData: {},
  brokenFormat: {},
  other: { mustIncludeMessage: true },
};

export const ENTER_KEY = 'Enter';
export const NUMPAD_ENTER_KEY = 'NumpadEnter';
export const TAB_KEY = 'Tab';
