import React, { useContext } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { getDesignTokens, setDocumentUiVariables } from '@eposnow/ui-core';
import { UIContext } from './UIContext';

declare module '@mui/material/styles' {
  interface TypographyVariants {
    eventTitle: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    eventTitle?: React.CSSProperties;
  }

  interface SimplePaletteColorOptions {
    light?: string;
    main: string;
    dark?: string;
    contrastText?: string;
    '160p'?: string;
    '190p'?: string;
  }

  interface PaletteColor {
    light: string;
    main: string;
    dark: string;
    contrastText: string;
    '160p'?: string;
    '190p'?: string;
  }

  interface TypeText {
    primary: string;
    secondary: string;
    disabled: string;
    contrast?: string;
  }
}

declare module '@mui/material/index' {
  interface Color {
    '4p'?: string;
    '8p'?: string;
    '12p'?: string;
    '16p'?: string;
    '18p'?: string;
    '23p'?: string;
    '26p'?: string;
    '30p'?: string;
    '50p'?: string;
    '54p'?: string;
    '56p'?: string;
    '65p'?: string;
    '70p'?: string;
    '87p'?: string;
    '0dp'?: string;
    '1dp'?: string;
    '2dp'?: string;
    '4dp'?: string;
    '8dp'?: string;
    '12dp'?: string;
    '16dp'?: string;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    eventTitle: true;
  }
}



const ThemeContext = ({ children }: { children: React.ReactNode }) => {
  const { colorMode } = useContext(UIContext);
  const designTokens = getDesignTokens(colorMode ?? 'light');
  if(designTokens.components) {
    designTokens.components.MuiSwitch = {
      styleOverrides: {
        root: {
          ":hover": {
            boxShadow: '0 0 0 1.5px rgba(20, 20, 20, 0.9)',
            borderRadius: '10%',
          },
        },
      },
    }
  }
  console.log('TOKENS', designTokens);
  setDocumentUiVariables(colorMode);

  return <ThemeProvider theme={createTheme(designTokens)}>{children}</ThemeProvider>;
}

export default ThemeContext;
