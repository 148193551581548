import {Box, IconButton, Typography} from '@mui/material';
import * as React from 'react';
import {CSSProperties, ReactElement} from 'react';
import {UIContext} from '../context/UIContext';

export interface DrawerTitlebarProps {
  iconLeft: ReactElement;
  iconRight: ReactElement;
  title: string;
  onClose?: () => void;
}

const titleStyle = {
  paddingBottom: '12px',
  marginBottom: '30px',
  paddingLeft: '16px',
  paddingRight: '16px',
  paddingTop: '16px',
};

const headerStyle: CSSProperties = {
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: '18px',
  paddingTop: '2px',
  paddingBottom: '12px',
};

const DrawerTitlebar = (props: DrawerTitlebarProps) => {
  const { iconLeft, iconRight, title, onClose } = props;
  const { colorMode, isMobile, isTablet } = React.useContext(UIContext);

  return (
    <Box
      sx={{
        display: 'flex',
        borderBottom: (theme) =>
          `1px ${
            colorMode === 'light' ? theme?.palette?.grey?.['12p'] : theme?.palette?.grey?.['23p']
          } solid`,
        ...titleStyle,
      }}
    >
      <Box sx={{ color: 'text.secondary', marginRight: '16px' }} onClick={() => (isMobile || isTablet) && onClose ? onClose() : {}}>
        {iconLeft}
      </Box>
      <Typography sx={headerStyle} variant="h2">
        {title}
      </Typography>
      {onClose && !isMobile && !isTablet && (
        <IconButton
          autoFocus
          data-qa-id="drawerCloseButton"
          aria-label="close"
          color="inherit"
          data-testid="closeDrawer"
          size="small"
          onClick={onClose}
          sx={{marginLeft: 'auto', color: 'text.secondary'}}
        >
          {iconRight}
        </IconButton>
      )}
      {onClose && (isMobile || isTablet) && (
        <Box sx={{marginLeft: 'auto'}} >
          {iconRight}
        </Box>
      )}
    </Box>
  );
};

export default DrawerTitlebar;
