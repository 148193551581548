import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import ShowChartOutlinedIcon from '@mui/icons-material/ShowChartOutlined';
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined';
import { Box, Typography, useTheme } from '@mui/material';
import { MainHeader } from '@eposnow/ui-core';
import TroncTable from './components/TroncTable';
import { TroncRow, TroncSummary } from '../../models/tronc';
import { GetTroncRequest, TroncApiResponse, useTroncs } from '../../api/tronc';
import { TroncFiltersContext } from '../../context/TroncFiltersContext';
import { defer, GBP } from '../../helpers/helpers';
import WarningBanner from '../../components/WarningBanner';
import { MILLIS_PER_DAY } from '../../helpers/dateUtils';

const SHOW_WEEKEND_NOTICE_DATE_KEY_TRONC = 'alerts.weekendNoticeTronc';


const Tronc = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const { filters } = useContext(TroncFiltersContext);
  const [page, setPage] = useState(0);
  const [troncData, setTroncData] = useState<TroncApiResponse<TroncRow> | undefined>();
  const [enableFetching, setEnableFetching] = useState<boolean>(Boolean(filters?.location?.id));
  const isWeekend = ([0,6].includes(new Date().getDay()));
  const [showWeekendNotice, setShowWeekendNotice] = useState(false);
  const linkNavigation = useCallback(
    (path: string | undefined) => {
      if (path) {
        navigate(path);
      }
    },
    [navigate]
  );


  const {
    refetch: fetchTroncs,
    isFetching,
    isError,
  } = useTroncs<TroncRow>(
    {
      startDate: filters.periodFrom,
      endDate: filters.periodTo,
      location: filters?.location?.id
    } as GetTroncRequest,
    'troncs',
    {
      enabled: enableFetching,
      onSuccess: (response) => {
        setTroncData(response);
      }
    }
  );

  useEffect(() => {
      const showWeekendNoticeDate = +(localStorage.getItem(SHOW_WEEKEND_NOTICE_DATE_KEY_TRONC) || '');
      setShowWeekendNotice(showWeekendNoticeDate <= Date.now());
    }, []);

  useEffect(() =>
      defer(() => {
        setPage(0);
        if(!filters.periodFrom || !filters.periodTo || !filters?.location?.id) {
          setTroncData(undefined);
          return;
        }
        setEnableFetching(true);
        fetchTroncs().then();
      }),
    [filters, fetchTroncs]
  );

  return(
    <>
    <Box
      sx={{
        marginLeft: 0,
        marginBottom: { xs: 1, sm: 2 },
        paddingX: { xs: 2, sm: 0 },
      }}
    >
      {isWeekend && showWeekendNotice &&
      <WarningBanner
      title={t('screens.transactions.weekendNotice')}
      alertDescription={t('screens.transactions.weekendNoticeTextTronc')}
      buttonText={t('actions.gotIt')}
      action={() => {
          setShowWeekendNotice(false);
          localStorage.setItem(SHOW_WEEKEND_NOTICE_DATE_KEY_TRONC, String(Date.now() + (MILLIS_PER_DAY * 2)));
        }}
      />
    }
      <MainHeader
        title={t('screens.troncReport.title')}
        nav={[
          {
            text: t('nav.reporting'),
            link: '/transactions',
            icon: <ShowChartOutlinedIcon sx={{ mr: 1 }} fontSize="inherit" />,
            dataQaId: 'headerNavTransactionReports',
          },
          {
            text:t('nav.troncReport'),
            link: '/tronc',
            icon: (<SummarizeOutlinedIcon sx={{ mr: 1 }} fontSize="inherit" />
            ),
            dataQaId: 'headerNavCompletedTroncReports'
          },
        ]}
        theme={theme}
        linkNavigationFunction={linkNavigation}
     >
          <Typography variant="body1">
            {t('screens.troncReport.description')}
          </Typography>
        </MainHeader>
        </Box>
        <TroncTable
          rows={troncData?.troncRows || []}
          summary={troncData?.summary || {} as TroncSummary}
          isLoading={isFetching}
          currency={troncData?.currency || GBP}
          // Display empty table on load. Only instance where filters location doesn't exist
          isError={isError}
          page={page}
          setPage={setPage}
          refresh={() => fetchTroncs().then()}
          />
      </>
    )
  }


export default Tronc;
