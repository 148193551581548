import { GridColDef, GridColumnVisibilityModel } from "@mui/x-data-grid-pro";

/**
 * Creates a column visibility model based on an array of columns. Columns order is preserved.
 * @param columns - Columns definition.
 */
export const createVisibilityModel = (columns: GridColDef[]): GridColumnVisibilityModel =>
  columns.map(v => v.field).reduce((visibilityModel: GridColumnVisibilityModel, fieldName: string) => {
    const model = {
    ...visibilityModel,
    [fieldName]: false,
  }
  return model
}, {});

/**
 * Updates a column visibility model field order based on the order of an array of columns.
 * @param columns - Columns definition.
 * @param oldVisibilityModel - Old visibility model.
 */
export const reorderVisibilityModel = (columns: GridColDef[], oldVisibilityModel: GridColumnVisibilityModel): GridColumnVisibilityModel =>
  columns.map(v => v.field).reduce((visibilityModel: GridColumnVisibilityModel, fieldName: string) => {
    const model = {
    ...visibilityModel,
    [fieldName]: oldVisibilityModel[fieldName],
  }
  return model
}, {});

/**
 * Builds a default Grid Column Visibility Model based on the columns available to the user. If the user is admin, adds admin columns as well. 
 * @param currentColumns - User's current visibility model.
 * @param adminView - Specifies if it's admin user.
 */
export const buildDefaultTransactionColumnVisibilityModel = ((currentColumns: GridColumnVisibilityModel, adminView: boolean | undefined, pspReferences: boolean | undefined): GridColumnVisibilityModel => 
  ({...currentColumns,
    ...(adminView ? { companyGuid: Boolean(adminView) } : {}),
    date: true,
    amount: true,
    tax: true,
    paymentMethod: true,
    staff: true,
    ...(pspReferences ? { pspReferences: true } : {}),
    ...(adminView ? { activeFlagAdmin: Boolean(adminView) } : {}),
  })
);

/**
 * Saves the user's columns visibility preferences.
 * @param columns - Visibility model.
 * @param adminView - Specifies if it's admin user.
 */
export const saveTransactionSelectedColumnsPreferences = (columns: GridColumnVisibilityModel, adminView: boolean | undefined): void => sessionStorage.setItem(adminView ? 'adminTransactionsVisibleColumns' : 'transactionsVisibleColumns', JSON.stringify(columns));

/**
 * Gets the user's columns visibility preferences.
 * @param adminView - Specifies if it's admin user.
 * @returns - Visibility model or an empty object if there aren't saved preferences.
 */
export const getTransactionSelectedColumnsPreferences = (adminView: boolean | undefined): GridColumnVisibilityModel => {
  const visibleColumnsJSON = sessionStorage.getItem(adminView ? 'adminTransactionsVisibleColumns' : 'transactionsVisibleColumns');
  return visibleColumnsJSON ? JSON.parse(visibleColumnsJSON) as GridColumnVisibilityModel : {};
};

/**
 * Saves the user's columns order preferences.
 * @param columns - Order of the columns.
 * @param adminView - Specifies if it's admin user.
 */
export const saveTransactionColumnsOrderPreferences = (columns: GridColDef[], adminView: boolean | undefined): void => {
  sessionStorage.setItem(adminView ? 'adminTransactionsColumnsOrder' : 'transactionsColumnsOrder', JSON.stringify(columns.map((column) => column.field)))
};

/**
 * Gets the user's columns order preferences.
 * @param adminView - Specifies if it's admin user.
 * @returns - Array with ordered column field names or an empty array if there aren't saved preferences.
 */
export const getTransactionColumnsOrderPreferences = (adminView: boolean | undefined) => {
  const order = sessionStorage.getItem(adminView ? 'adminTransactionsColumnsOrder' : 'transactionsColumnsOrder');
  return order ? JSON.parse(order) : []
};
