import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { useTheme } from '@mui/material';
import { AlertMessage } from '@eposnow/ui-core';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { UIContext } from '../context/UIContext';
import { UserContext } from '../context/UserContext';
import { MILLIS_PER_DAY } from '../helpers/dateUtils';

interface WarningBannerProps {
  title: string;
  buttonText: string;
  alertDescription: string;
  action?: () => void;
} 

const WarningBanner = ({
  title,
  buttonText,
  alertDescription,
  action,
}: WarningBannerProps) => {

  const theme = useTheme();
  const { isMobile, isTablet } = useContext(UIContext);
  const { locale } = useContext(UserContext);
  const { t } = useTranslation();

  return (
    <AlertMessage type="warning" locale={locale} theme={theme} styles={{ marginBottom: 2 }} iconAlignment='baseline' isMobile={isMobile || isTablet}>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Typography sx={{ fontWeight: 'bold' }}>{title}</Typography>
        <Typography>{alertDescription}</Typography>
        <Button sx={{ marginLeft: 'auto', fontWeight: 'bold', color: theme.palette.warning.dark }} onClick={action}>
          {buttonText}
        </Button>
      </Box>
    </AlertMessage>
  );
}

export default WarningBanner;
